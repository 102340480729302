<template>
  <section
    class='contacts-map'
    v-observe-visibility="{callback: animation, once: true}"
  >
    <div class="map">
      <transition name="fade">
        <img
          src="@/assets/contacts/contacts-map.jpg"
          class="map-bg"
          alt="map"
          v-show="inView"
        >
      </transition>
      <transition name="fade-scale-down">
        <img
          src="@/assets/contacts/planes.png"
          class="map-overlay"
          alt="map overlay"
          v-show="inView"
        >
      </transition>
    </div>
    <div class='pins'>
      <transition-group name="fade">
        <button
          type="button"
          class="pin"
          v-for="(office, officeId) in content"
          :key="office?.city"
          @mouseenter="handlePinEnter(office, officeId, $event)"
          @mouseleave="handlePinLeave(office, officeId)"
          @click="handlePinClick(office, officeId, $event)"
          :style="`top: ${office?.map_position_y}%; left: ${office?.map_position_x}%;`"
          :class="{'is-opened': currentOpened === `pin${officeId}`}"
          v-show="inView"
        >
          <Icon name="pin" />
          <div class="pin-label">{{office?.city}}</div>
        </button>
      </transition-group>
    </div>
    <teleport
      to='#popups'
      :disabled="breakpoint !== 'sm'"
    >
      <transition
        name='fade'
        mode="out-in"
      >
        <OfficePopover
          v-if="currentActive"
          :content='currentActive'
          :key='currentActive'
          v-click-outside="handleClosePopoverOutside"
          @closePopover="handleClosePopover"
          :positionData="activePinData"
        />
      </transition>
    </teleport>
  </section>
</template>

<script>
import Icon from '../../../components/Icon.vue';
import OfficePopover from './OfficePopover.vue';
import { animationMixin } from '../../../components/mixins/animation';

export default {
  components: { OfficePopover, Icon },
  name: 'ContactsMap',
  mixins: [animationMixin],
  data() {
    return {
      currentActive: null,
      currentOpened: null,
      activePinData: null,
    };
  },
  props: {
    content: {
      type: Array,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    handlePinEnter(office, id, event) {
      if (this.breakpoint !== 'sm') {
        this.currentActive = office;

        if (this.currentOpened !== `pin${id}`) {
          this.currentOpened = null;
        }

        this.activePinData = {
          map: this.$el,
          pin: office,
          pinEl: event.currentTarget,
        };
      }
    },
    handlePinLeave(office, id) {
      if (this.breakpoint !== 'sm') {
        if (this.currentOpened === `pin${id}`) return;

        this.currentActive = null;
        this.currentOpened = null;
        this.activePinData = null;
      }
    },
    handlePinClick(office, id, event) {
      this.activePinData = {
        map: this.$el,
        pin: office,
        pinEl: event.currentTarget,
      };
      this.currentActive = office;
      this.currentOpened = `pin${id}`;
    },
    handleClosePopover() {
      this.currentActive = null;
      this.currentOpened = null;
      this.activePinData = null;
    },
    handleClosePopoverOutside(event) {
      if (
        this.currentOpened
        && !(event.target.matches('.pin') || event.target.closest('.pin'))
      ) {
        this.currentActive = null;
        this.currentOpened = null;
        this.activePinData = null;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.contacts-map {
  position: relative;
  display: block;

  @include rule(
    transform,
    (
      sm: translate3d(-33.333333%, 0, 0),
    )
  );

  @include wRule(
    width,
    (
      xl: 100%,
      sm: 1920,
    )
  );

  .map {
    position: relative;
    width: 100%;

    @include ratio(1920, 800);
  }

  .map-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    // &.fade-enter-active, &.fade-leave-active {
    //   transition-delay: .3s;
    // }
  }

  .map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform-origin: 50% 50%;

    &.fade-scale-down-active,
    &.fade-scale-down-active {
      transition-delay: 0.3s;
    }
  }

  .pins {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .pin {
    position: absolute;
    transform: translate3d(-50%, -100%, 0);
    line-height: 0;

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.3s ease;

      @for $i from 1 to 20 {
        &:nth-child(#{$i}) {
          transition-delay: 0.4s + 0.05s * $i;
        }
      }
    }

    .icon {
      fill: color(basic-light);
      transition: fill $ease-main;

      @include wRule(
        width,
        (
          xl: 23,
          lg: 23,
          sm: 23,
        )
      );
    }

    .pin-label {
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      line-height: 1.28;
      font-weight: 700;
      color: color(basic-light);
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 18,
          sm: 28,
        )
      );

      @include wRule(
        margin-top,
        (
          xl: 4,
          lg: 4,
          sm: 4,
        )
      );
    }

    &:hover,
    &.is-opened {
      .icon {
        fill: color(main);
      }
    }
  }
}
</style>
