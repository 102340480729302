<template>
  <section
    class="office-card"
    v-observe-visibility="{callback: animation, once: true}"
  >
    <div class="header">
      <transition name="fade-down">
        <h3
          class="title"
          v-show="inView"
        >{{content?.city}}, {{content?.Country}}</h3>
      </transition>
      <transition name="draw-line">
        <div
          class="line"
          v-show="inView"
        ></div>
      </transition>
    </div>
    <transition name="fade-down">
      <ul
        class="contacts"
        v-show="inView"
      >
        <li class="contact">
          <Link
            :tag="'a'"
            :href="`tel:${content?.phone?.replace(/ /gi, '')}`"
          >{{content?.phone}}</Link>
        </li>
        <li class="contact">
          <Link
            :tag="'a'"
            :href="`mailto:${content?.email}`"
          >{{content?.email}}
          </Link>
        </li>
      </ul>
    </transition>
    <div class="bottom">
      <transition name="fade-down">
        <div
          class="bottom-content"
          v-show="inView"
        >
          <div class="address">{{content?.address}}</div>
          <div class="location-link">
            <transition name="draw-line">
              <div
                class="line"
                v-if="breakpoint === 'sm'"
                v-show="inView"
              ></div>
            </transition>
            <Link
              :tag="'a'"
              :href="content?.link"
              target="_blank"
            >
            <template #default>
              {{getName('contacts_office_button')?.default}}
            </template>
            <template #end>
              <Icon name="pin-outline" />
            </template>
            </Link>
          </div>
        </div>
      </transition>
      <transition name="draw-line">
        <div
          class="line"
          v-if="breakpoint !== 'sm'"
          v-show="inView"
        ></div>
      </transition>
    </div>
  </section>
</template>

<script>
import Link from '../../../components/Link.vue';
import Icon from '../../../components/Icon.vue';
import { animationMixin } from '../../../components/mixins/animation';

export default {
  components: { Link, Icon },
  mixins: [animationMixin],
  name: 'OfficeCard',
  props: {
    content: {
      type: Object,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
};
</script>

<style lang="scss" scoped>
.office-card {
  .header {
    display: flex;
    align-items: center;
  }

  ::v-deep(.title) {
    display: inline-block;
    flex: none;
    font-weight: 700;
    line-height: 1.72;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 32,
        lg: 24,
        sm: 32,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 20,
          lg: 20,
          sm: 20,
        )
      );
    }

    &.fade-down-enter-active,
    &.fade-down-leave-active {
      transition-delay: 0.3s;
    }
  }

  .line {
    display: block;
    flex: 1;
    background-color: currentColor;
    transform-origin: 100% 50%;

    @include wRule(
      height,
      (
        xl: 4,
        lg: 3,
        sm: 4,
      )
    );

    &.draw-line-enter-active,
    &.draw-line-leave-active {
      transition-delay: 0.3s;
    }
  }

  .contacts {
    display: block;
    width: 100%;

    @include wRule(
      margin-top,
      (
        xl: 4,
        lg: 4,
        sm: 4,
      )
    );

    &.fade-down-enter-active,
    &.fade-down-leave-active {
      transition-delay: 0.4s;
    }
  }

  .contact {
    display: block;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 32,
        lg: 24,
        sm: 32,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 14,
          lg: 8,
          sm: 14,
        )
      );
    }
  }

  .bottom {
    display: block;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 106,
          lg: 86,
          sm: 66,
        )
      );
    }

    .line {
      flex: none;
      width: 100%;
      transform-origin: 0 50%;

      @include screen("sm", max) {
        flex: 1;
        width: auto;
      }

      @include wRule(
        margin-top,
        (
          xl: 32,
          lg: 32,
          sm: 0,
        )
      );

      &.draw-line-enter-active,
      &.draw-line-leave-active {
        transition-delay: 0.6s;
      }
    }
  }

  .bottom-content {
    display: flex;
    align-items: center;
    line-height: 1.5;

    @include rule(
      justify-content,
      (
        xl: space-between,
        sm: flex-start,
      )
    );

    @include rule(
      align-items,
      (
        xl: center,
        sm: flex-start,
      )
    );

    @include rule(
      flex-direction,
      (
        xl: row,
        sm: column,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 28,
      )
    );

    &.fade-down-enter-active,
    &.fade-down-leave-active {
      transition-delay: 0.5s;
    }
  }

  .address {
    @include rule(
      width,
      (
        sm: 100%,
      )
    );
  }

  .location-link {
    font-weight: 700;

    @include screen("sm") {
      display: flex;
      align-items: center;
      width: 100%;
    }

    @include wRule(
      margin-top,
      (
        sm: 20,
      )
    );

    .link {
      flex: none;
    }

    .line {
      transform-origin: 0 50%;

      &:not(:last-child) {
        @include wRule(
          margin-right,
          (
            sm: 30,
          )
        );
      }

      &.draw-line-enter-active,
      &.draw-line-leave-active {
        transition-delay: 0.6s;
      }
    }
  }
}
</style>
