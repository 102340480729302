<template>
  <div class="page-container">
    <section class="contacts-lead">
      <Breadcrumbs />
      <ContactsMap :content="pageContent" />
      <Offices :content="pageContent" />
      <TeamSection v-if="false" />
      <Feedback :data="content?.feedback" />
    </section>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import ContactsMap from './components/ContactsMap.vue';
import Feedback from './components/Feedback.vue';
import Offices from './components/Offices.vue';
import TeamSection from './components/TeamSection/TeamSection.vue';
import api from '../../tools/api';

export default {
  name: 'Contacts',
  components: {
    Breadcrumbs,
    ContactsMap,
    Offices,
    Feedback,
    TeamSection,
  },
  data() {
    return {
      pageContent: [],
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    content() {
      return {
        feedback: {
          Description: {
            Title: this.getName('form_feedback_title')?.secondary,
            Text: this.getName('form_feedback_description')?.default,
            ButtonName: this.getName('FormSubmitButton')?.tertiary,
          },
          FormTitle: this.getName('form_feedback_title')?.default,
          FormSubmitName: this.getName('FormSubmitButton')?.default,
          FormUrl: '/',
          Groups: {
            Contacts: {
              Icon: 'person',
              Name: this.getName('fieldset_contacts')?.default,
              Fields: {
                Name: {
                  Label: this.getName('field_name')?.secondary,
                },
                Email: {
                  Label: this.getName('field_email')?.default,
                },
              },
            },
            Comment: {
              Icon: 'comment',
              Name: this.getName('fieldset_comment')?.default,
              Fields: {
                Message: {
                  Label: this.getName('field_comment')?.default,
                },
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async getPageContent() {
      const results = await api('offices', true);
      this.contentLoading = !results.statusText === 'ok';
      this.pageContent = results.data;
    },
  },
  created() {
    this.getPageContent();
  },
  watch: {
    lang() {
      this.getPageContent();
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-lead {
  @include wRule(
    padding-top,
    (
      sm: 120,
    )
  );

  @include wRule(
    padding-bottom,
    (
      xl: 195,
      lg: 95,
      sm: 100,
    )
  );

  .offices {
    @include wRule(
      margin-top,
      (
        xl: 144,
        lg: 80,
        sm: 120,
      )
    );
  }
}

::v-deep(.breadcrumbs) {
  @include wRule(
    margin-bottom,
    (
      xl: 78,
      lg: 40,
      sm: 40,
    )
  );
}
</style>
