<template>
  <div class="feedback">
    <div class="container">
      <BaseForm
        :data="data"
        ref="baseForm"
      >
        <template #default>
          <VeeForm
            method="post"
            :action="data?.FormUrl"
            :validation-schema="validationShema"
            @submit="onSubmit"
            v-if="!showMessage"
          >
            <fieldset class="fieldset">
              <div
                class="subtitle"
                v-inview-animate="{name: 'fade-down', duration: 300, delay: 300}"
              >
                <Icon :name="data?.Groups?.Contacts?.Icon" />
                <span class="subtitle-text">{{data?.Groups?.Contacts?.Name}}</span>
              </div>
              <div class="fields grid-2">
                <div class="wrapper">
                  <BaseInput
                    name="name"
                    type="text"
                    :label="data?.Groups?.Contacts?.Fields?.Name?.Label"
                    v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 400}"
                  />
                  <ErrorMessage
                    name="name"
                    class="error"
                  />
                </div>

                <div class="wrapper">
                  <BaseInput
                    name="email"
                    type="email"
                    :label="data?.Groups?.Contacts?.Fields?.Email?.Label"
                    v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 500}"
                  />
                  <ErrorMessage
                    name="email"
                    class="error"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset class="fieldset">
              <div
                class="subtitle"
                v-inview-animate="{name: 'fade-down', duration: 300, delay: 600}"
              >
                <Icon :name="data?.Groups?.Comment?.Icon" />
                <span class="subtitle-text">{{data?.Groups?.Comment?.Name}}</span>
              </div>
              <div class="fields">
                <div class="wrapper">
                  <BaseTextarea
                    name="message"
                    :label="data?.Groups?.Comment?.Fields?.Message?.Label"
                    v-inview-animate:repeat="{name: 'input-transition', duration: 300, delay: 700}"
                  />
                  <ErrorMessage
                    name="message"
                    class="error"
                  />
                </div>
              </div>
            </fieldset>
            <VButton
              :tag="'button'"
              type="submit"
              :disabled="submiting"
              v-inview-animate="{name: 'fade-down', duration: 300, delay: 800}"
            >{{data?.FormSubmitName}}</VButton>
          </VeeForm>
          <SuccessMessage
            :data="sendSuccess ? successMessage : errorMessage"
            v-else
            @modal-closed="closeMessage"
          />
        </template>
      </BaseForm>
    </div>
  </div>
</template>

<script>
import BaseForm from '../../../components/Forms/BaseForm.vue';
import { Form as VeeForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { setLocale } from 'yup';
import BaseInput from '../../../components/Forms/components/BaseInput.vue';
import Icon from '../../../components/Icon.vue';
import BaseTextarea from '../../../components/Forms/components/BaseTextarea.vue';
import VButton from '../../../components/VButton.vue';
import SuccessMessage from '../../../components/SuccessMessage.vue';

export default {
  components: {
    BaseForm,
    VeeForm,
    BaseInput,
    ErrorMessage,
    Icon,
    BaseTextarea,
    VButton,
    SuccessMessage,
  },
  name: 'Feedback',
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      submiting: false,
      validationShema: null,
      sendSuccess: false,
      showMessage: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    getErrorsList() {
      const errorList = this.$store.getters.getErrorList;
      return errorList;
    },
    successMessage() {
      return {
        Icon: 'check',
        Title: this.getName('form_request_success_title')?.default,
        Text: this.getName('form_request_success_description')?.default,
        ButtonName: this.getName('close_button')?.default,
      };
    },
    errorMessage() {
      return {
        Icon: 'cross',
        Title: this.getName('form_request_error_title')?.default,
        Text: this.getName('form_request_error_description')?.default,
        ButtonName: this.getName('close_button')?.default,
      };
    },
  },
  methods: {
    closeMessage() {
      this.showMessage = false;
      if (this.breakpoint === 'sm') {
        this.$refs.baseForm.closePopup();
      }
    },
    async onSubmit(data, actions) {
      this.submiting = true;

      const response = await fetch(`${process.env.VUE_APP_FORM_HOST}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data?.email,
          name: data?.name,
          title: 'Заявка cо страницы контактов с сайта SKYPRO AVIATION',
          message: data?.message,
        }),
      });

      console.log(data);

      if (response.ok) {
        this.submiting = false;
        this.sendSuccess = true;
        this.showMessage = true;

        actions.resetForm({
          email: '',
          name: '',
          message: '',
          phone: '',
        });
      } else {
        this.submiting = false;
        this.sendSuccess = false;
        this.showMessage = true;
        console.error('Error on submit');
      }
    },
    setValidationShema(lang) {
      const errorList = this.getErrorsList[lang];
      setLocale(errorList);
      this.validationShema = yup.object({
        name: yup.string().required(),
        email: yup
          .string()
          .required()
          .email(),
        message: yup.string().required(),
      });
    },
  },
  watch: {
    lang(value) {
      this.setValidationShema(value);
    },
  },
  created() {
    const lang = this.$store.getters.getLang;
    this.setValidationShema(lang);
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  display: block;

  &:not(:first-child) {
    @include wRule(
      margin-top,
      (
        xl: 183,
        lg: 80,
        sm: 124,
      )
    );
  }
}
</style>
