<template>
  <div
    class="slider-arrows"
    :class="{'is-hide': isLock}"
    v-observe-visibility="{ callback: animation, once:true}"
  >
    <button
      type="button"
      class="arrow prev"
      :disabled="swiper?.isBeginning"
      @click="swiper?.slidePrev()"
    >
      <Icon
        name="next"
        class="icon"
      />
    </button>
    <button
      type="button"
      class="arrow"
      :disabled="swiper?.isEnd"
      @click="swiper?.slideNext()"
    >
      <Icon
        name="next"
        class="icon"
      />
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue';
import { animationMixin } from './mixins/animation';

export default {
  name: 'SliderArrows',
  components: { Icon },
  mixins: [animationMixin],
  props: {
    theme: {
      type: String,
    },
    swiper: {
      // eslint-disable-next-line no-undef
      type: Object,
      required: true,
    },
    text: {
      type: [String, Number],
    },
  },
  computed: {
    themeClass() {
      return this.theme ? `theme-${this.theme}` : '';
    },
    isLock() {
      return this.swiper?.isLocked;
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-arrows {
  display: flex;
  align-items: center;
  color: color(main);
  position: relative;

  &.is-hide {
    display: none;
  }

  &.animation {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.is-animated {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
  }

  .arrow {
    display: block;
    transform-origin: 50% 50%;
    color: inherit;
    transition: color $ease-main, opacity $ease-main;
    font-size: 0;
    line-height: 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -100%;
      top: -100%;
      right: -100%;
      bottom: -100%;
    }

    &.prev {
      transform: rotate(180deg);
    }

    &:hover {
      @include screen("sm", min) {
        color: color(dark);
      }
    }

    &[disabled] {
      opacity: 0.22;
      cursor: default;
      pointer-events: none;
    }

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 85,
          lg: 74,
          sm: 90,
        )
      );
    }
  }

  .icon {
    display: block;

    @include wRule(
      height,
      (
        xl: 60,
        lg: 50,
        sm: 43,
      )
    );
  }

  /*
  Themes
   */
  &.theme-dark {
    color: color(basic);

    .arrow[disabled] {
      opacity: 0.2;
    }
  }

  &.theme-white {
    color: color(basic-light);
  }
}
</style>
