<template>
  <section class="base-form">
    <div
      class="description"
      v-show="breakpoint === 'sm'"
    >
      <h3
        class="title-small"
        v-inview-animate="{name: 'fade-down', duration: 300, delay: 100}"
      >{{data?.Description?.Title}}</h3>
      <div
        class="text"
        v-inview-animate="{name: 'fade-down', duration: 300, delay: 200}"
      >{{data?.Description?.Text}}</div>
      <VButton
        :tag="'button'"
        :type="'button'"
        @click="openPopup"
        v-inview-animate="{name: 'fade-down', duration: 300, delay: 300}"
      >{{data?.Description?.ButtonName}}</VButton>
    </div>
    <teleport
      to="#popups"
      :disabled="teleportActive"
    >
      <transition name="fade">
        <div
          class="content"
          v-show="popupActive"
        >
          <div class="header">
            <h3
              class="title-small"
              v-inview-animate="{name: 'fade-down', duration: 300, delay: 100}"
            >{{data?.FormTitle}}</h3>
            <Close
              v-if="breakpoint === 'sm'"
              @click="closePopup"
            />
          </div>
          <slot name="default"></slot>
        </div>
      </transition>
    </teleport>
  </section>
</template>

<script>
import Close from '../Close.vue';
import VButton from '../VButton.vue';
import { freeze, unfreeze } from '../../tools/blockScroll';

export default {
  name: 'BaseForm',
  components: {
    Close,
    VButton,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      popupActive: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    openPopup() {
      this.popupActive = true;

      freeze();
    },
    closePopup() {
      this.popupActive = false;

      unfreeze();
    },
    statePopup() {
      if (window.matchMedia('(min-width: 768px)').matches) {
        this.popupActive = true;
      } else {
        this.popupActive = false;
      }

      unfreeze();
    },
  },
  watch: {
    breakpoint(value) {
      this.statePopup();

      if (value === 'sm') {
        this.teleportActive = false;
      } else {
        this.teleportActive = true;
      }
    },
  },
  created() {
    this.statePopup();

    if (window.matchMedia('(min-width: 768px)').matches) {
      this.teleportActive = true;
    } else {
      this.teleportActive = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.base-form {
  position: relative;
  display: block;
  box-sizing: border-box;
  border-style: solid;
  border-color: currentColor;

  @include wRule(
    border-width,
    (
      xl: 2,
      lg: 2,
      sm: 2,
    )
  );

  @include wRule(
    padding,
    (
      xl: 68 42 152,
      lg: 40 38 134,
      sm: 70 40 138,
    )
  );

  .description {
    text-align: center;
    margin: 0 auto;

    @include wRule(
      max-width,
      (
        xl: 450,
        lg: 380,
        sm: 405,
      )
    );

    ::v-deep(.text) {
      letter-spacing: -0.02em;
      font-weight: 400;
      line-height: 1.6;

      @include wRule(
        font-size,
        (
          xl: 20,
          lg: 16,
          sm: 24,
        )
      );

      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: 10,
            lg: 4,
            sm: 16,
          )
        );
      }
    }
  }

  ::v-deep(.v-button) {
    position: absolute;
    box-sizing: border-box;
    outline-style: solid;
    outline-color: color(bg);
    bottom: 0;
    right: 50%;
    transform: translate3d(50%, 0, 0);

    @include wRule(
      outline-width,
      (
        xl: 28,
        lg: 15,
        sm: 18,
      )
    );

    @include wRule(
      margin-top,
      (
        xl: 86,
        lg: 86,
        sm: 50,
      )
    );

    @include wRule(
      min-width,
      (
        xl: 260,
        lg: 185,
        sm: 405,
      )
    );
  }
}

.content {
  display: block;
  width: 100%;
  box-sizing: border-box;

  @include wRule(
    padding,
    (
      sm: 184 40 60,
    )
  );

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;

    @include wRule(
      padding,
      (
        sm: 0 40,
      )
    );

    @include wRule(
      border-bottom-width,
      (
        sm: 2,
      )
    );

    @include screen("sm", max) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: pxtovw(116, sm);
      border-bottom-style: solid;
      border-bottom-color: rgba(0, 0, 0, 0.12);
    }

    ::v-deep(.close) {
      color: color(basic);
      fill: color(basic);

      @include wRule(
        margin-right,
        (
          sm: -10,
        )
      );
    }

    ::v-deep(.title-small) {
      @include wRule(
        font-size,
        (
          sm: 32,
        )
      );
    }

    @include wRule(
      margin-bottom,
      (
        xl: 52,
        lg: 35,
        sm: 0,
      )
    );
  }

  ::v-deep(.fieldset) {
    display: block;
    box-sizing: border-box;
    width: 100%;

    &:not(:last-of-type) {
      @include wRule(
        margin-bottom,
        (
          xl: 75,
          lg: 46,
          sm: 64,
        )
      );
    }
  }

  ::v-deep(.subtitle) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: -0.02em;

    @include wRule(
      font-size,
      (
        xl: 24,
        lg: 20,
        sm: 32,
      )
    );

    .icon {
      fill: color(icon-blue);

      @include wRule(
        width,
        (
          xl: 32,
          lg: 32,
          sm: 44,
        )
      );

      @include wRule(
        height,
        (
          xl: 32,
          lg: 32,
          sm: 44,
        )
      );

      @include wRule(
        margin,
        (
          xl: 0 10 0 -2,
          lg: 0 10 0 -10,
          sm: 0 10 0 -10,
        )
      );
    }
  }

  ::v-deep(.v-button) {
    right: 0;
    bottom: 0;
    transform: translate3d(0, 0, 0);
    outline-style: solid;
    outline-color: color(bg);

    @include wRule(
      outline-width,
      (
        xl: 28,
        lg: 15,
        sm: 18,
      )
    );

    @include wRule(
      position,
      (
        xl: absolute,
        sm: static,
      )
    );

    @include wRule(
      min-width,
      (
        xl: 258,
        lg: 185,
        sm: 100%,
      )
    );

    @include wRule(
      margin-top,
      (
        sm: 108,
      )
    );
  }

  ::v-deep(.fields) {
    display: block;
    box-sizing: border-box;
    width: 100%;

    @include wRule(
      padding,
      (
        xl: 0 40,
        lg: 0 32,
        sm: 0,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 32,
          lg: 12,
          sm: 23,
        )
      );
    }

    &.grid-3 {
      display: grid;
      grid-template-columns: 1fr pxtovw(350, xl) 1fr;

      @include wRule(
        column-gap,
        (
          xl: 100,
          lg: 52,
          sm: 0,
        )
      );

      @include wRule(
        row-gap,
        (
          xl: 40,
          lg: 34,
          sm: 0,
        )
      );

      @include screen("lg", max) {
        grid-template-columns: 1fr pxtovw(340, lg);
      }

      @include screen("sm", max) {
        display: block;
      }
    }

    &.grid-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @include wRule(
        column-gap,
        (
          xl: 56,
          lg: 52,
          sm: 0,
        )
      );

      @include wRule(
        row-gap,
        (
          xl: 40,
          lg: 36,
          sm: 0,
        )
      );

      @include screen("sm", max) {
        display: block;
      }
    }
  }

  @include screen("sm", max) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    position: fixed;
    background-color: color(bg);
    overflow: auto;
  }

  ::v-deep(.wrapper) {
    display: block;
    width: 100%;
    box-sizing: border-box;

    &.lg-100 {
      @include screen("lg", max) {
        grid-column: 1 / 3;
      }
    }

    &:not(:last-child) {
      @include wRule(
        margin-bottom,
        (
          sm: 40,
        )
      );
    }
  }

  ::v-deep(.phone) {
    display: flex;
    justify-content: flex-start;
  }

  ::v-deep(.calling-code) {
    flex: none;

    @include wRule(
      width,
      (
        xl: 124,
        lg: 120,
        sm: 183,
      )
    );

    &:not(:last-child) {
      @include wRule(
        margin-right,
        (
          xl: 24,
          lg: 20,
          sm: 36,
        )
      );
    }
  }

  ::v-deep(.phone-number) {
    width: auto;
    flex: 1;
  }

  ::v-deep(.error) {
    display: block;
    color: color(error);
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.28;
    box-sizing: border-box;

    @include wRule(
      padding,
      (
        xl: 12 0,
        lg: 12 0,
        sm: 16 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 14,
        lg: 14,
        sm: 14,
      )
    );
  }
}
</style>
