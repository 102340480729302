<template>
  <label
    class="base-textarea"
    :class="[{'is-error': errorMessage}, {'is-top': inputValue}, {'is-disabled': disabled}]"
  >
    <textarea
      :name="name"
      :id="name"
      rows="1"
      :placeholder="label"
      @input="handleChange"
      @blur="handleBlur"
      v-model="inputValue"
      :disabled="disabled"
      ref="textarea"
    ></textarea>
    <div class="label">{{label}}</div>
  </label>
</template>

<script>
import { useField } from 'vee-validate';
import autosize from 'autosize';

export default {
  name: 'BaseTextarea',
  props: {
    name: {
      type: String,
      reqired: true,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  mounted() {
    autosize(this.$refs.textarea);
  },
  beforeUnmounted() {
    const autosizeDestroyEvt = document.createEvent('Event');
    autosizeDestroyEvt.initEvent('autosize:destroy', true, false);
    this.$refs.textarea.dispatchEvent(autosizeDestroyEvt);
  },
};
</script>

<style lang="scss" scoped>
.base-textarea {
  position: relative;
  display: block;

  &::after {
    @include pseudo;

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: currentColor;

    @include wRule(
      height,
      (
        xl: 2,
        lg: 2,
        sm: 2,
      )
    );
  }

  &.is-inview-animation-input-transition {
    &::after {
      transition: transform 0.5s ease;
      transition-delay: inherit;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }

    .label {
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.25s ease;
      transition-delay: inherit;
    }
  }

  &.is-inview-animation-active {
    &::after {
      transform: scaleX(1);
    }

    .label {
      opacity: 1;
    }
  }

  textarea {
    display: block;
    box-sizing: border-box;
    letter-spacing: -0.02em;
    width: 100%;
    resize: none;
    overflow: hidden;
    color: currentColor;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );

    &::placeholder {
      color: transparent;
    }
  }

  .label {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    box-sizing: border-box;
    pointer-events: none;
    transform-origin: 0 100%;
    transition: transform $ease-main;
    text-rendering: geometricPrecision;

    @include wRule(
      padding,
      (
        xl: 16 0,
        lg: 16 0,
        sm: 23 0,
      )
    );

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        sm: 24,
      )
    );
  }

  &.is-error {
    &::after {
      background-color: color(error);
    }
  }

  &.is-top .label,
  textarea:focus ~ .label {
    transform: scale(0.6) translate3d(0, -121%, 0);
  }
}
</style>
